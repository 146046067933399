<template>
  <section class="administration">
    <header>
      <h1>Administration</h1>
    </header>

    <section class="template-storage">
      <p-llm-configurations />
    </section>

    <section class="settings">
      <div class="settings-header">
        <h2>MS-Graph Connector Settings</h2>
        <span v-if="!isEditable" class="edit-icon" @click="toggleEdit">&#9998;</span>
        <span v-else class="save-icon" @click="updateSettings">
          <MdIcon size="md" name="content-save-alert-outline" />
        </span>
      </div>
      <form class="form-group" @submit.prevent="updateSettings" >
        <div>
          <p>Fast lane schedule</p>
            <p-text-field
              id="fastLaneCheckUpdates"
              v-model="settings.fastLaneCheckUpdates"
              type="text"
              :disabled="!isEditable"
            />
        </div>
        <div>
          <p>Fast lane emails</p>
            <ul class="email-list">
              <li v-for="(entry, index) in settings.fastLaneEmails" :key="index" class="email-list-item">
                  <span class="email-list-item-entry">{{ entry }}</span>
                  <span v-if="isEditable" class="delete-icon" @click="deleteEmail(index)">
                    <MdIcon size="sm" name="delete" />
                  </span>
              </li>
                <div v-if="isEditable">
                  <p-text-field 
                    v-model="newEmail" 
                    type="email"
                    placeholder="Enter email"
                    autocomplete="off"
                    @keyup.enter="addEmail"/>

                  <p-button
                    v-if="isEditable"
                    type="button"
                    color="primary"
                    :disabled="isConfigureAuthenticationRequestPending || !isAuthenticationConfigurationValid"
                    @click="addEmail"
                    >Add</p-button
                  >
                </div>
            </ul>
        </div>
      </form>
    </section>


    <section>
      <h2>Re-index Patent Search</h2>
      <p>
        If your Portfolio data model was changed and you need to apply those changes to Patent Search, you can manually trigger Update Search
        operation. <br />Beware that it make take a while before all changes are applied.
      </p>
      <p-button color="primary" type="submit" :disabled="isReIndexRequestPending || !$hasPermission('administration')" @click="reindex"
        >Update</p-button
      >
    </section>

    <section>
      <h2>Fix migrations</h2>
      <p>
        After workflow templates cloning/migration some milestone tasks might be not fully migrated. <br />Verify all tasks match their milestone
        template and fix any inconsistencies.
      </p>
      <p-button color="primary" type="submit" :disabled="isFixMilestones || !$hasPermission('administration')" @click="fixMilestones">Fix</p-button>
    </section>

    <section class="authentication">
      <h2>Manage Authentication</h2>
      <p>You can configure which authentication providers will be available for users. Make sure you have at least one active.</p>
      <ul>
        <li>
          <p-checkbox v-model="authentication.local" :disabled="!$hasPermission('administration')" label="Local"></p-checkbox>
        </li>
        <li>
          <p-checkbox v-model="authentication.microsoft" :disabled="!$hasPermission('administration')" label="Microsoft"></p-checkbox>
        </li>
      </ul>
      <p-button
        v-if="$hasPermission('administration')"
        color="primary"
        type="submit"
        :disabled="isConfigureAuthenticationRequestPending || !isAuthenticationConfigurationValid"
        @click="configureAuthentication"
        >Save</p-button
      >
    </section>

    <section class="template-storage">
      <h2>Template Storage Backup</h2>
      <p>
        You can download a backup of Template Storage. It includes all objects and files for specifications, forms, documents, drawings and messages.
      </p>
      <p-button color="primary" type="submit" @click="exportBackup">Export</p-button>
      <br />
      <br />
      <p>
        If you already have a backup - you can upload it here. We will compare the content in your .zip with what exists in the system at this moment.
        Items that are identical will be skipped, items that are different will be imported as new.
      </p>
      <p-file-select v-if="$hasPermission('administration')" color="primary" :accept="'.zip'" :disabled="isImportBackupPending" @change="importBackup"
        >Import</p-file-select
      >
      <br />
      <ul v-if="!isGetImportCollectionPending" class="import-log-list">
        <h3>Import history</h3>
        <li v-for="item in importCollection" :key="item.id" class="import-log-list-item">
          <div>{{ item.id }}</div>
          <div :class="['status', item.status.split(' ').join('-')]">
            {{ item.status }}
          </div>
          <div>
            {{ new Date(item.createdAt).toLocaleString() }}
          </div>
          <div>
            <div v-if="item.status === 'completed'" class="action-icon" @click.prevent.stop="downloadLog(item.downloadUrl)">
              ⤓
            </div>
          </div>
        </li>
      </ul>
    </section>

    <section class="template-storage">
      <h2>Categorization</h2>
      <div>
        <p>Remove all existing tags. You can not undo this action.</p>
        <p-button color="secondary" type="submit" :disabled="isPruneTagsPending || !$hasPermission('administration')" @click="pruneTags"
          >Prune</p-button
        >
      </div>
      <br />
      <div>
        <p>Re-apply them using categorization queries.</p>
        <p-button color="primary" type="submit" :disabled="isReTagPending || !$hasPermission('administration')" @click="reTag">Re-Tag</p-button>
      </div>
      <br />
      <div>
        <p>Download query scores per application.</p>
        <p-button color="primary" type="submit" @click="downloadScores">Download</p-button>
      </div>
      <br />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import FileSelect from '@/components/common/FileSelect';
import LlmConfigurations from '@/components/llm-configurations/ModelsConfigurations';
import httpClient from '@/utils/httpClient';
import MdIcon from '@/components/common/MdIcon';
import TextField from '@/components/common/TextField';

export default {
  components: {
    'p-button': Button,
    'p-checkbox': Checkbox,
    'p-file-select': FileSelect,
    'p-llm-configurations': LlmConfigurations,
    MdIcon,
    'p-text-field': TextField,
  },
  data() {
    return {
      authentication: {
        local: false,
        microsoft: false
      },
      isFixMilestones: false,
      isEditable: false,
      newEmail: '',
    };
  },
  async created() {
    await this.loadSettings();
  },
  computed: {
    ...mapState({
      isReIndexRequestPending: s => s.administration.isReIndexRequestPending,
      isReIndexRequestFailed: s => s.administration.isReIndexRequestFailed,
      isConfigureAuthenticationRequestPending: s => s.authentication.isUpdateRequestPending,
      isReTagPending: s => s.administration.isReTagPending,
      isPruneTagsPending: s => s.administration.isPruneTagsPending,
      isImportBackupPending: s => s.administration.isImportBackupPending,
      isGetImportCollectionPending: s => s.administration.isGetImportCollectionPending,
      importCollection: s => s.administration.importCollection,
      providers: s => s.authentication.providers,
      exportUrl: s => s.administration.exportUrl,
      isSettingsUpdatePending: s=> s.administration.isSettingsUpdatePending,
      settings: s=> s.administration.settings
    }),
    isAuthenticationConfigurationValid() {
      for (const key of Object.keys(this.authentication)) {
        if (this.authentication[key]) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    providers() {
      for (const key of Object.keys(this.authentication)) {
        this.authentication[key] = this.providers.includes(key);
      }
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('authentication/initialize'),
      this.$store.dispatch('administration/getImportCollection'),
      this.$store.dispatch('administration/getExportUrl'),
      this.$store.dispatch('administration/getSettings')
    ]);
  },
  methods: {
    deleteEmail(index) {
    this.settings.fastLaneEmails.splice(index, 1);
    },
    async loadSettings() {
      try {
        await this.$store.dispatch('administration/getSettings');
      } catch (error) {
        this.$toast.error({
          title: 'Failed to load settings',
          message: 'Please try again later or contact support.',
        });
      }
    },
    toggleEdit() {
      this.isEditable = !this.isEditable; 
    },
    addEmail() {
      if (this.newEmail && !this.settings.fastLaneEmails.includes(this.newEmail)) {
        this.settings.fastLaneEmails.push(this.newEmail);
        this.newEmail = '';
      }
    },
    async updateSettings() {
      try {
        await this.$store.dispatch('administration/updateSettings', this.settings);
        this.$toast.success({
          title: 'Settings Updated',
          message: 'Your settings have been updated successfully.',
        });
        this.isEditable = false;
      } catch (error) {
        this.$toast.error({
          title: 'Failed to update settings',
          message: 'Please try again later or contact support.',
        });
      }
    },
    async reindex() {
      try {
        const response = await this.$store.dispatch('administration/reindex');

        this.$toast.success({
          title: 'Re-index triggered',
          message: `${response.length} applications will be updated.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Re-index failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async fixMilestones() {
      try {
        this.isFixMilestones = true;
        const response = await httpClient.get('/api/workflows/v2/workflows/fix');

        if (!response.length) {
          this.$toast.success({
            title: 'Finished fixing milestones',
            message: `No issues found`
          });
        } else {
          const errors = response.filter(r => r.error);

          if (errors.length) {
            for (const error of errors) {
              this.$toast.error({
                title: `Failed to migrate milestone ${error.milestoneId}`,
                message: error.error
              });
            }
          }

          const succedded = response.filter(r => !r.error);

          if (succedded.length) {
            this.$toast.success({
              title: 'Finished fixing milestones',
              message: `${succedded.length} milestones fixed`
            });
          }
        }
      } catch (error) {
        this.$toast.error({
          title: 'Fixing milestones failed',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        this.isFixMilestones = false;
      }
    },
    async configureAuthentication() {
      try {
        const collection = [];
        for (const key of Object.keys(this.authentication)) {
          if (this.authentication[key]) {
            collection.push(key);
          }
        }
        const response = await this.$store.dispatch('authentication/configure', { collection });

        this.$toast.success({
          title: 'Authentication configured',
          message: `${collection.length} provider(s) are active now.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to configure authentication',
          message: `Please, try again later or contact our development team.`
        });
        throw e;
      }
    },
    exportBackup() {
      try {
        window.open(`/api/template-storage${this.exportUrl}`, '_blank');
      } catch (e) {
        this.$toast.error({
          title: 'Export failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async importBackup([file] = []) {
      const lock = this.$lock();
      try {
        const response = await this.$store.dispatch('administration/importBackup', { file });
        await this.$store.dispatch('administration/getImportCollection');
        this.$toast.success({
          title: 'Import started',
          message: 'Import is running in the background. You can come back later to this page to check status or to get logs of the operation.'
        });
      } catch (e) {
        this.$toast.error({
          title: 'Import failed',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        lock?.release();
      }
    },
    downloadLog(url) {
      try {
        window.open(`/api/template-storage${url}`, '_blank');
      } catch (e) {
        this.$toast.error({
          title: 'Failed to download log',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async reTag() {
      try {
        const response = await this.$store.dispatch('administration/reTag');

        this.$toast.success({
          title: 'Re-Tag completed',
          message: `${response.completed.length} queries completed, ${response.failed.length} queries failed. It might take a while to see changes applied. `
        });
      } catch (e) {
        this.$toast.error({
          title: 'Re-Tag failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async pruneTags() {
      try {
        const response = await this.$store.dispatch('administration/pruneTags');

        this.$toast.success({
          title: 'Prune tags completed',
          message: `All tags were removed. It might take a while to see changes applied.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Prune failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async downloadScores() {
      try {
        const { downloadUrl } = await httpClient.get('/api/categorization/queries/scores-url');
        const url = `/api/categorization${downloadUrl}`;
        window.open(url, '_blank');
      } catch (e) {
        this.$toast.error({
          title: 'Failed to download scores',
          message: `Please, try again later or contact our development team.`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.administration {
  width: 100%;
  height: 100%;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;
    position: relative;
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }

  > section {
    padding: 1.5rem 2rem;
    margin: 0 1rem;

    h2 {
      color: var(--theme-on-surface-dark);

      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    h3 {
      color: var(--theme-on-surface-dark);

      font-size: 0.9rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 0.25rem;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-highlight);
    }
  }

    .settings-header {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      h2 {
        margin: 0;
        margin-right: 0.5rem; 
      }

      .edit-icon,
      .save-icon {
        display: flex;
        align-items: center; 
        justify-content: center; 
        cursor: pointer;
        color: var(--theme-primary);
      }
    }

    .form-group {
      margin-bottom: 5px;
      width: max-content;
      min-width: 320px;
    }

    .email-list {
      list-style: none;
      margin-top: 5px;
      min-width: 320px;
      padding: 0;

      .email-list-item {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 0;
        border-bottom: 1px solid var(--theme-highlight);
        font-size: 0.85rem;
        border-right: 1px solid var(--theme-highlight);

        .email-list-item-entry {
          border-left: 1px solid var(--theme-highlight);
        }

        .delete-icon {
            margin-left: auto;
            cursor: pointer;
            color: var(--theme-primary);
            border-left: 1px solid var(--theme-highlight);
          }

        > * {
          padding: 0.5rem 0.5rem;


          &:last-child {
            border-left: 1px solid var(--theme-highlight);

          }
        }

        &:first-child {
          border-top: 1px solid var(--theme-highlight);
        }
      }
    }

  .import-log-list {
    width: max-content;
    .import-log-list-item {
      display: grid;
      grid-template-columns: 250px 100px 175px 30px;
      border-top: 1px solid var(--theme-highlight);

      > div {
        border-left: 1px solid var(--theme-highlight);
        padding: 0.35rem 0.5rem;
        font-size: 0.85rem;

        &:last-child {
          border-right: 1px solid var(--theme-highlight);
        }

        &.status {
          font-weight: bold;
          justify-self: center;

          &.in-progress {
            color: #f9a825;
          }
          &.failed {
            color: #da2d2d;
          }
          &.completed {
            color: #2e815b;
          }
        }
      }

      .action-icon {
        font-weight: 700;
        color: var(--theme-primary);
        cursor: pointer;
        justify-self: center;
      }

      &:last-child {
        border-bottom: 1px solid var(--theme-highlight);
      }
    }
  }
}
</style>
